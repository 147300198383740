var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainWrapper', [_vm.isLoading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : [_vm.myExtensions.length === 0 ? _c('NoDataMolecule', {
    attrs: {
      "info": 'You do not have Extensions. Click the create extension button.',
      "icon": 'extension',
      "buttonText": 'Create Extension',
      "title": 'My Extensions'
    },
    on: {
      "action": function action($event) {
        return _vm.createExtension($event);
      }
    }
  }) : _c('ExtensionTableMolecule', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData,
      "title": 'My Extensions',
      "buttonText": 'Create Extension',
      "mode": 'add',
      "sort-option": _vm.sortOption,
      "isLoading": _vm.isLoading,
      "maxHeight": _vm.getHeight
    },
    on: {
      "action": function action($event) {
        return _vm.createExtension($event);
      }
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <MainWrapper>
    <TalpaLoaderWrapper v-if="isLoading" class="loader" />
    <template v-else>
      <NoDataMolecule
        v-if="myExtensions.length === 0"
        :info="'You do not have Extensions. Click the create extension button.'"
        :icon="'extension'"
        :buttonText="'Create Extension'"
        :title="'My Extensions'"
        @action="createExtension($event)"
      />
      <ExtensionTableMolecule
        :columns="columns"
        :tableData="tableData"
        :title="'My Extensions'"
        :buttonText="'Create Extension'"
        :mode="'add'"
        :sort-option="sortOption"
        :isLoading="isLoading"
        @action="createExtension($event)"
        :maxHeight="getHeight"
        v-else
      />
    </template>
  </MainWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TrashIcon } from 'vue-feather-icons'
import { DateTime } from 'luxon'
import { concat, orderBy, partition, get } from 'lodash'

import { FlashMessages } from '@common/singletons'
import { TalpaLoaderWrapper } from '@common/components'
import permissionsMixin from '@/mixins/permissions'
import localesMixin from '@/mixins/locales'

import ExtensionTableMolecule from './ExtensionTableMolecule.vue'
import NoDataMolecule from './NoDataMolecule.vue'
import Button from '@/components/Atomic/Atoms/ButtonStyleless.vue'
import BadgeAtom from '@/components/Atomic/Atoms/BadgeAtom.vue'

import MY_EXTENSIONS_QUERY from '#/graphql/extensionBuilder/myExtensions.gql'
import CREATE_EXTENSION_MUTATION from '#/graphql/extensionBuilder/createExtension.gql'
import DELETE_EXTENSION_MUTATION from '#/graphql/extensionBuilder/deleteExtension.gql'

const MainWrapper = styled('div')`
  display: grid;
  margin: 1rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  height: calc(100% - 1rem);
  border-radius: 0.5rem;

  .link {
    cursor: pointer;
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 1rem;
    color: ${p => p.theme.colors.primary};

    > div,
    button {
      padding: 0;
      cursor: pointer;
      > svg {
        color: ${p => p.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  mixins: [permissionsMixin, localesMixin],
  components: {
    ExtensionTableMolecule,
    MainWrapper,
    NoDataMolecule,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      myExtensions: [],
      sortOption: {
        multipleSort: false,
        sortChange: params => {
          this.sortChange(params)
        },
      },
    }
  },
  computed: {
    isLoading() {
      return this.$apollo?.queries.myExtensions?.loading
    },
    title() {
      return this.$t('extensions.table.title')
    },
    locale() {
      return this.uiSettings?.language ?? 'DE_DE'.toLowerCase().replace('_', '-')
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'name',
          title: this.$tc('name', 1),
          align: 'left',
          sortBy: 'asc',
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field]
            if (row.status === 'draft') {
              return (
                <a class="link" on-click={() => this.$router.push({ name: 'CreateExtension', params: { id: row?.rowKey } })}>
                  {{ text }}
                </a>
              )
            } else {
              return <div>{{ text }}</div>
            }
          },
        },
        { field: 'updatedAt', key: 'updatedAt', title: 'Last Updated', align: 'center', sortBy: 'asc' },
        {
          field: 'status',
          key: 'status',
          title: 'Status',
          align: 'center',
          width: 100,
          sortBy: 'asc',
          renderBodyCell: ({ row }) => {
            const status = row.status[0].toUpperCase() + row.status.slice(1)
            return <BadgeAtom label={status} color={status === 'Draft' ? 'green' : undefined} />
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          width: '10%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            if (row.status === 'draft') {
              return (
                <span class="actions">
                  <Button on-click={() => this.confirmDeleteExtension(row)}>
                    <TrashIcon size="1.3x" />
                  </Button>
                </span>
              )
            } else {
              return (
                <span class="actions">
                  <Button disabled>
                    <TrashIcon size="1.3x" />
                  </Button>
                </span>
              )
            }
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.myExtensions?.map(extension => {
        return {
          rowKey: extension.id,
          name: extension?.name,
          updatedAt: DateTime.fromISO(extension?.updatedAt).setLocale(this.locale).toRelative(),
          status: extension?.status,
        }
      })

      return tableData
    },
    getHeight() {
      return window.innerHeight
    },
  },
  methods: {
    textSearch(query) {
      this.searchQuery = query
    },
    confirmDeleteExtension(row) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Extension',
        instance: row,
        labelKey: 'name',
        onConfirm: this.deleteExtension,
        onConfirmArgs: row,
      })
    },
    async deleteExtension(row) {
      const id = row.rowKey
      try {
        const deleteExtension = await this.$apollo.mutate({
          mutation: DELETE_EXTENSION_MUTATION,
          variables: {
            id,
          },
        })
        if (deleteExtension) {
          this.$root.$emit('closeOverlay')
          FlashMessages.$emit('success', `Successfully Removed the Extension ${row.name}`, {
            timeout: 3000,
          })
          this.$apollo.queries.myExtensions.refetch()
        }
      } catch (err) {
        this.$root.$emit('closeOverlay')
        FlashMessages.$emit('error', err)
      }
    },
    async createExtension() {
      try {
        const extension = await this.$apollo.mutate({
          mutation: CREATE_EXTENSION_MUTATION,
          variables: {
            data: {
              name: 'New Extension',
              description: '',
              assetIds: [],
            },
          },
        })
        const extensionId = extension?.data?.createExtension?.id
        if (extensionId) {
          this.$router.push({
            name: 'CreateExtension',
            params: {
              id: extensionId,
            },
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    sortChange(params) {
      const sortKey = Object.keys(params).filter(key => params[key] !== '')
      const sortDirection = params[sortKey]
      const partitions = partition(this.myExtensions, x => !!get(x, sortKey, null))
      this.myExtensions = concat(orderBy(partitions[0], [sortKey], [sortDirection]), partitions[1])
    },
  },
  apollo: {
    myExtensions: {
      query: MY_EXTENSIONS_QUERY,
      variables() {
        return {
          where: {
            deleted: {
              equals: false,
            },
          },
        }
      },
      fetchPolicy: 'network-only',
    },
  },
}
</script>
